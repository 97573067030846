import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import Footer from "../Footer/Footer";
import appleDownload from '../../assets/img/appledownload.svg';
import playStoreDownload from '../../assets/img/googledownload.png';
const SharedBasket =  () => {

    return <div className="flex flex-col h-screen space-y-2">
         <div className='flex px-8 pb-4 bg-transparent z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase font-semibold text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>

    <div className='flex flex-col grow space-y-6 bg-gray-100 items-center justify-center mt-8'>
        <p className="text-4xl font-bold">Telecharger l'application </p>
                <a href="https://apps.apple.com/us/app/freeatiz/id6460259085">
                    <img className='w-60' src={appleDownload} />
                </a>
                <a>
                    <img className='w-60' src={playStoreDownload} />
                </a>
               </div>

    <Footer />

    </div>

}

export default SharedBasket;