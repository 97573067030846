import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png';
import MenuIcon from '@mui/icons-material/Menu';

const Cgu =  () => {

    return <div>
        <div className='flex px-8 absolute top-0 pb-4 bg-transparent w-full  z-30  pt-2 items-center justify-between'>
    <p >
      <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>

    <div className="w-4/5 mx-auto mt-28"> 
  
  <p className="text-3xl font-bold mb-6">
    Conditions générales d'utilisation freeatiz
  </p>
  <p className="mb-6 ">
    Date de Mise à jour : 04/02/2024
  </p>
  <p className="mb-6">
      La plateforme « freeatiz » propose, via son site internet et deux applications, la mise en relation entre des
      clients, personnes morales ou physiques souhaitant commander des repas (ci-après collectivement « les
      Clients » et individuellement « le Client »), et des restaurateurs (ci-après collectivement « les
      Commerçants » et individuellement « le Commerçant »). Les repas commandés pourront être offerts par le
      Client à un tiers personne physique (ci-après “Tiers Bénéficiaire”).
  </p>

  <p className="mb-6 font-bold">
    Attention : Avant de cliquer sur l’icône « J’ai pris connaissance et accepte les CGU » à la fin du
    présent document, vous devez le lire attentivement dans son intégralité.
  </p>

  <p className="mb-6">
      Les <span className="font-bold">différentes étapes à suivre</span> pour conclure les présentes Conditions Générales d’Utilisation (&quot;CGU&quot;)
      sont les suivantes :
      Etape 1) Accéder au site<br></br>
      Étape 2) Créer un espace client<br></br>
      Étape 3) Fournir les informations nécessaires à l’accès au service :<br></br>
      Nom,<br></br>
      Prénom,<br></br>
      Adresse email,<br></br>
      Mot de passe.<br></br>
      Étape 4) Cliquer sur l’icône <span className="font-bold">« J’ai pris connaissance et accepte les CGU » </span><br></br>
  </p>

  <p className="font-bold mb-6">
    En cliquant sur l’icône « J’ai pris connaissance et accepte les CGU » :
  </p>

  <p className="mb-6">
      Vous garantissez être une personne physique âgée d&#39;au moins dix-huit ans ou une personne morale
      disposant de la pleine capacite juridique à conclure les présentes CGU,
      Vous exprimez votre accord sur l’intégralité du contenu des présentes CGU : vous vous obligez à l’égard
      de la plateforme, votre cocontractant, à en respecter l’ensemble des termes sans aucune réserve,
      <span className="font-bold">Vous garantissez avoir lu et compris les présentes CGU, y compris toutes les sections qui y sont
        contenues.</span>
  </p>

  <p className="mb-6">
    <span className="font-bold">La langue proposée</span> pour la conclusion des présentes CGU est le français.
  </p>



  <p className="mb-6">
    <span className="font-bold"> Les modalités d'archivage </span> des présentes CGU sont les suivantes : les CGU sont archivées dans les serveurs de freeatiz. <span className="font-bold">Les conditions d'accès </span> aux CGU archivées sont les suivantes : les CGU sont accessibles dans une rubrique du site appelée CGU.  

  </p>

  <p className="mb-6">
    Dès lors que le client accepte les présentes CGU, il donne son <span className="font-bold">accord préalable et exprès</span> à ce que ces CGU commencent à être  <span className="font-bold">exécutées immédiatement</span> par la plateforme. En conséquence, le client renonce expressément à exercer son droit de rétractation.  
    
  </p>


  <p className="mb-6">
    <span className="font-bold">La plateforme est :</span><br></br>
    ·	freeatiz SAS au capital de 100 euros,<br></br>
    ·	Dont le siège social est situé au 7 allée che guevara,93200 Saint-denis,<br></br>
    ·	Dont le numéro d’enregistrement au RCS de Bobigny est 978 526 499<br></br>
    ·	Dont le numéro de TVA est le FR27978526499.<br></br>
     (ci-	après la <span className="font-bold">« Plateforme »</span>)<br></br>
    
    <span className="font-bold">Pour toutes les réclamations,</span> le client peut contacter la Plateforme :<br></br>
    ·	Par email : support@freeatiz.com<br></br>
    ·	Par courrier : freeatiz, 7 allée che guevara,93200 Saint-denis.<br></br>
    ·	Par téléphone : 0749021018.
    
    
  </p>


  <p className="mb-6">
    Le client est informé de la possibilité de recourir en cas de contestation, à la procédure de <span className="font-bold">médiation de la consommation</span>, le <span className="font-bold">médiateur</span> compétent étant [à compléter]. 
    
  </p>


  <p className="mb-6">
    <span className="font-bold">La durée de l’offre des services</span>
     proposées par la Plateforme correspond à toute la durée pendant laquelle le site est accessible.
  </p>

  <p className="mb-6">
      de la Plateforme est proposée à titre gratuit. 
  </p>
  <p className="mb-6">
    L’offre des services  par la Plateforme n’inclut pas le coût de l'utilisation des moyens d’accès (téléphone, ordinateur, tablette numérique) du client, tel que fixé par l’abonnement téléphone ou internet de ce dernier.

  </p>
  <p className="mb-6">
    Les services fournis par la Plateforme sont directement exécutés via l’accès au site. 

  </p>
  <p className="mb-6">
   <span className="font-bold"> Le droit applicable</span> aux présentes CGU est le droit français, la juridiction compétente étant celle de droit commun.

  </p>

  <p className="mb-6 font-bold text-2xl">
    1.	Définitions

  </p>

  <p className="mb-6">
    Dans les présentes CGU, chacun des termes, ci-après s'entend, avec ou sans majuscule, au sens de la définition qui suit :

  </p>
  
  <p className="mb-6">
    -	Les termes <span className="font-bold">« Base(s) de Données »</span> désignent toutes les bases de données et sous bases de données accessibles via le Site. 

  </p>
  
  <p className="mb-6">
    -	Le terme <span className="font-bold">« Client »</span>désigne la personne qui accepte les présentes Conditions Générales d’Utilisation. Le Client est une personne physique ou morale utilisant le Site pour le bénéfice d’un Tiers Bénéficiaire, dans le cadre d’un usage strictement personnel ou professionnel, mais non-commercial. 


  </p>
  
  <p className="mb-6">
    -	Les abréviations <span className="font-bold">« CGU » </span>désignent le présent contrat, régissant les conditions d’utilisation du Site et la relation contractuelle entre la Plateforme et le Client.


  </p>
  
  <p className="mb-6">
    -	Les termes <span className="font-bold">« CGV » </span> désignent les conditions applicables à la Commande des Produits et disponibles ici.  


  </p>

  <p className="mb-6">
    -	Le terme <span className="font-bold">« Commande » </span>désigne tout achat par un Client d’un ou plusieurs Produits à un Commerçant via le Site. 


  </p>
  

  <p className="mb-6">
    -	Le terme <span className="font-bold">« Commerçants » </span>désigne tous les commerçants qui proposent aux Clients leurs Produits sur le Site.


  </p>
  


  <p className="mb-6">
    -	Le terme <span className="font-bold">« Commerçant »</span> désigne le cocontractant du Client en cas de Commande. L’identité du Commerçant dépend de chaque Commande du Client. Les informations sur l’identité du Commerçant sont accessibles dans sa Fiche Commerçant. 


  </p>
  


  <p className="mb-6">
    -	Les termes <span className="font-bold">« Contenus-Commerçant »</span> désignent tous les contenus fournis par les Commerçants que la Plateforme publie sur le Site et qui sont accessibles aux Clients notamment via leur Espace Client et/ou au public via le Site.


  </p>
  


  <p className="mb-6">

    Par exemple, la Plateforme publie la Fiche Commerçant et de la Fiche Produits sur le Site mais ne saurait être responsable de l’illicéité ou de l’absence de véracité des informations fournies par les Commerçants et contenues dans la Fiche Produit et la Fiche Commerçant.

  </p>
  


  <p className="mb-6">
    -	Les termes <span className="font-bold"></span> désignent tous les contenus édités par le Client au sens de l’article 6-III de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique qu’il créé dans le cadre de la mise en relation avec un Commerçant et qui sont accessibles aux Commerçants et/ou au public, via le Site.


  </p>
  


  <p className="mb-6">
    -	Les termes <span className="font-bold">« Dessin »</span> et <span className="font-bold">« Modèle » </span>désignent tous dessins et modèles ayant fait l’objet ou faisant l’objet, avant ou pendant la durée d’exécution des présentes CGU, d’un dépôt ou d’un enregistrement en tant que :
    o	dessin ou modèle,<br></br>
    o	enregistré(s) auprès d’un Registre :<br></br>
    ▪	national, <br></br>
    ▪	international, <br></br>
    ▪	communautaire, <br></br>
    ▪	d’enregistrement des dessins et modèles.<br></br>

  </p>
  


  <p className="mb-6">

    -	Les termes <span className="font-bold">« Espace Client » </span>désignent la partie privée du Site, à laquelle accède le Client notamment après avoir entré son identifiant et son mot de passe.
    L’Espace Client permet à ce dernier notamment de :<br></br>
    o	accéder, modifier, supprimer ses données personnelles,<br></br>
    o	accéder et gérer son panier d’achat,<br></br>
    o	passer et gérer des Commandes,<br></br>
    o	être informé sur l’état des Commandes (ex : prêtes pour le click & collect, annulées),<br></br>
    o	éditer des Contenus-Clients,<br></br>
    o	consulter les informations relatives à son utilisation du Site (ex : factures, historique des commandes, avis sur les Commerçants et les Commandes),<br></br>
    o	avoir accès à des offres spécifiques (ex : code promo),<br></br>
    o	avoir accès à un système d’aide en ligne,<br></br>
    o	demander la suppression de son Espace Client.<br></br>
  </p>
  

  <p className="mb-6">
    -	Les termes <span className="font-bold">« Espace Commerçant »</span> désignent la partie privée du Site, à laquelle accède le Commerçant, après avoir entré son identifiant et son mot de passe.
    L’Espace Commerçant permet à ce dernier notamment de :<br></br>
    o	accéder, modifier, supprimer ses données personnelles et professionnelles,<br></br>
    o	accepter, gérer et annuler toute Commande d’un Client,<br></br>
    o	consulter les informations relatives aux Commandes passées,<br></br>
    o	demander la suppression de son Espace Commerçant.

  </p>
  

  <p className="mb-6">
    -	Les termes <span className="font-bold">« Fiche Commerçant »</span> désignent la zone du Site dans laquelle figurent l’identification du Commerçant, incluant le nom, le numéro individuel d'identification TVA intracommunautaire, l’adresse, le pays ainsi que la spécialité du Commerçant. 
  </p>
  

  <p className="mb-6">
    -	Le terme <span className="font-bold">« Litige » </span>est entendu au sens large et comprend toute réclamation, toute contestation, tout différend.


  </p>
  
  <p className="mb-6">
    -	Le terme <span className="font-bold">« Marque »</span> désigne tous signes distinctifs, de quelque nature que ce soit, ayant fait l’objet ou faisant l’objet, avant ou pendant toute la durée d’exécution des présentes CGU, d’un dépôt ou d’un enregistrement en tant que marque(s) sous quelque forme que ce soit et notamment en tant que :<br></br>

    o	marque(s)<br></br>
    ▪	nominative(s),<br></br>
    ▪	figurative(s),<br></br>
    ▪	semi-figurative(s), etc.<br></br>
    o	enregistrée(s) auprès d’un Office<br></br>
    ▪	national,<br></br>
    ▪	international,<br></br>
    ▪	communautaire d’enregistrement des marques.
  </p>
  <p className="mb-6">

    -	Les termes <span className="font-bold">« Moyens d’Accès »</span> désignent tous moyens informatiques et de communications électroniques permettant au Client d’accéder aux Services, tels qu’un ordinateur, une tablette, un téléphone, une montre connectée.

  </p>
  <p className="mb-6">
    -	Le terme <span className="font-bold">« Œuvre » </span>désigne toutes créations intellectuelles originales protégées par le droit d’auteur.


  </p>
  <p className="mb-6">

    -	Les termes <span className="font-bold">« Outils de Communication »</span> désignent tous les éléments :<br></br>

o	Permettant à la Plateforme de s’identifier commercialement, notamment auprès du Client,<br></br>
o	et qui sont protégés par un ou plusieurs droits de propriété de quelque nature que ce soit, et notamment un ou plusieurs droits de propriété intellectuelle.


  </p>


  <p className="mb-6">

    Les Outils de Communication incluent notamment :<br></br>

    o	les Marques, <br></br>
    o	les Dessins et Modèles,<br></br>
    o	les Œuvres sous quelque forme que ce soit (visuelle, audio, texte, etc.),<br></br>
    o	ainsi que : les noms commerciaux, les chartes graphiques, et couleurs, les logos, les enseignes, les slogans, les noms de domaines, sans que cette liste ne soit exhaustive.
    

  </p>

  <p className="mb-6">
    -	Le terme <span className="font-bold">« Produit »</span> ou <span className="font-bold">« Produits » </span>désigne les repas qui sont proposés par le Commerçant via le Site et fournis par ce dernier en cas de Commande. 


  </p>

  <p className="mb-6">

    -	Le terme <span className="font-bold">« Services »</span> désigne les services tels que définis à l’article « Description des Services ».

  </p>

  <p className="mb-6">
    -	Le terme <span className="font-bold">« Site »</span> désigne le site disponible à l’URL : www.freeatiz.com sur lequel les Produits peuvent être commandés par le Client ou les applications disponibles sous le nom freeatiz incluant leur partie publique, ainsi que leurs espaces privés, notamment l’Espace Client. 


  </p>


  <p className="mb-6">
-	Les termes <span className="font-bold">« Tiers Bénéficiaire »</span> désigne la personne physique qui se rendra auprès du Commerçant pour recevoir la Commande.
   
  </p>


  <p className="mb-6 text-2xl font-bold">
    2.	Objet
  </p>
  <p className="mb-6">
    Les présentes CGU ont pour objet de définir les conditions dans lesquelles :<br></br>
    -	la Plateforme  met à disposition du Client, gratuitement et dans le cadre d’une obligation de moyens, les Services tels que définis à l’article « Description des Services »,<br></br>
    -	ainsi que les conditions dans lesquelles le Client est autorisé à utiliser ces Services.
    
  </p>
  <p className="mb-6">
  La Plateforme accorde au Client un accès aux Services, révocable, non exclusif, à la condition que ce dernier respecte la totalité des stipulations définies dans les présentes CGU.
   
  </p>
  <p className="mb-6 text-2xl font-bold">
    3.	Durée
  </p>
  <p className="mb-6">
  Les présentes CGU entrent en vigueur et deviennent opposables au Client dès leur acceptation par ce dernier. Cette acceptation s’effectue en cliquant sur « J’ai pris connaissance et accepte les CGU ».
   
  </p>


  <p className="mb-6">
  Les présentes CGU sont conclues pour une durée indéterminée. 
     
    </p>


    <p className="mb-6 text-2xl font-bold">
      4.	Pré-requis
     
    </p>

    <p className="mb-6">
      Le Client reconnaît disposer de la compétence et des Moyens d’Accès nécessaires et adaptés pour accéder aux Services. Il reconnaît avoir la configuration matérielle et logicielle minimale requise. Il reconnaît en outre avoir sécurisé les Moyens d’Accès qu’il utilise, notamment en se dotant d’un anti-virus. 
    </p>

    <p className="mb-6">
      Le Client fait son affaire personnelle :<br></br>
      -	de la mise en place des Moyens d’Accès,<br></br>
      -	et des connaissances nécessaires à l'utilisation de ses Moyens d’Accès, ainsi que d'Internet, du Site, de l’Espace Client et plus généralement des Services.
    </p>

    <p className="mb-6 text-2xl font-bold">
    5.	Respecter la réglementation

    </p>

    <p className="mb-6">
    Le Client s'oblige à respecter toutes législations, règlementations et procédures qui s’imposent à lui compte-tenu de son lieu de connexion ou de son lieu de résidence, tant pour ce qui concerne l’accès au Site que l’utilisation des Services.
      
    </p>

    <p className="mb-6 text-2xl font-bold">
    6.	Description des Services
      
    </p>


    <p className="mb-6">

      La Plateforme met à disposition du Client, dans le cadre d’une obligation de moyens, les Services suivants :<br></br>

-	l’accès, grâce aux Moyens d’Accès du Client, au Site et en particulier à un Espace Client accessible via le Site, après saisie par le Client d’un identifiant et un mot de passe tels qu’il les a préalablement définis lors de la création de son Espace Client via le Site,<br></br>

-	l’accès à des offres de Produits proposés par des tiers, les Commerçants,<br></br>

-	la mise en relation avec des Commerçants permettant de passer des Commandes,<br></br>

-	la mise en ligne sur le Site des Contenus-Clients,<br></br>

-	la mise en ligne sur le Site des Contenus-Commerçants,<br></br>

-	la mise à disposition de toutes les informations concernant les Commandes, notamment les factures,<br></br>

-	la gestion du paiement, via un prestataire externe, pour le compte des Commerçants. 


    
     
    </p>


    <p className="mb-6 text-2xl font-bold">
    7.	Accès aux Services
     
    </p>


    <p className="mb-6">
      La Plateforme fait ses meilleurs efforts pour donner au Client un accès au Site 24 heures sur 24 et 7 jours sur 7, sauf notamment en cas de :<br></br>
      -	force majeure telle que définie à l’article « Force majeure »,<br></br>
      -	indisponibilité due à une maintenance notamment corrective ou évolutive prévue ou imprévue,<br></br>
      -	indisponibilité due à la faiblesse ou à l’absence de couverture réseaux d’accès internet par le fournisseur d’accès du Client,<br></br>
      -	et plus généralement, toute difficulté technique, de quelque nature que ce soit, non-imputable directement à la Plateforme pouvant survenir pour quelque cause que ce soit, et empêchant le Client:<br></br>
      o	d’accéder : <br></br>
      ▪	au Site et à ce qu’il contient,<br></br>
      ▪	à son Espace Client,<br></br>
      o	et/ou plus généralement d’utiliser les Services.
     
    </p>


    <p className="mb-6 text-2xl font-bold">
      8.	Destination des Services 

     
    </p>


    <p className="mb-6">

Les Services sont destinés à être utilisés uniquement par le Client dans le cadre d’un usage personnel ou professionnel, mais jamais commercial, ce que le Client reconnaît et garantit.

Les Services sont destinés à servir une finalité licite et non préjudiciable à un tiers, ce que le Client reconnaît et garantit.

     
    </p>



    <p className="mb-6 text-2xl font-bold">

      9.	Obligations du Client
  
    </p>



    <p className="mb-6">

      Le Client s’oblige, à tout moment, pendant toute la durée des présentes CGU, à respecter l’ensemble des stipulations contenues dans les présentes CGU, dans le cadre d’une obligation de résultat, notamment le présent article « Obligations du Client » et l’article « Utilisations Interdites ».<br></br>

Dans le cadre de l’utilisation des Services et en cas de Commande, le Client s’engage à consulter attentivement les CGV, disponibles ici, afin d’être informé sur les conditions applicables à toute Commande et à respecter les obligations qui lui incombent au titre de ces CGV.<br></br>
En conséquence, le Client s’oblige, à tout moment, pendant toute la durée des présentes CGU à respecter les règles de conduites suivantes (ci-après «Règles de Conduite»), en lien avec les Services et le Site:<br></br>

-	<span className="font-bold">Respect</span>  de la loi et réglementation applicable au Client,<br></br>

- <span className="font-bold">Honnêteté, bonne foi et probité </span>	du Client à l’égard de la Plateforme et des Commerçants, notamment dans les informations et données personnelles qu’il communique à ces derniers,<br></br>

- <span className="font-bold">Confidentialité absolue</span>	 des identifiant et mot de passe du Client donnant accès à son Espace Client,<br></br>

- <span className="font-bold">Respect et courtoisie</span>	 du Client à l’égard de la Plateforme et des Commerçants,<br></br>

- <span className="font-bold">Respect des droits de propriété,</span>	 et notamment des droits de propriété intellectuelle appartenant à la Plateforme et aux Commerçants,<br></br>

- <span className="font-bold">Immédiateté,</span>	 dans la mise à jour des informations concernant le Client qui aurait fait l’objet d’une modification. 

     
    </p>



    <p className="mb-6 text-2xl font-bold">
      10.	Utilisations interdites des Services 


     
    </p>



    <p className="mb-6">
        D’une manière générale, le Client s’interdit de quelque manière que ce soit, d’être ou de tenter d’être l’auteur d’un acte positif, d’une faute, d’une omission, d’une défaillance, d’une négligence, sous quelque forme que ce soit, susceptible de causer ou causant un préjudice, de quelque nature que ce soit, à la Plateforme.

     
    </p>

    <p className="mb-6 text-xl font-bold">
      a.	Atteinte aux droits d’autrui
    </p>


    <p className="mb-6">
      Dans le cadre de l’utilisation des Services, le Client s'interdit notamment, de quelque manière que ce soit, de :<br></br>

      -	tenter de nuire, ou nuire, tenter de porter préjudice ou porter préjudice <br></br>
      o	à la Plateforme,<br></br>
      o	aux Commerçants,<br></br>
      o	et/ou plus généralement à un tiers (y compris un autre Client), <br></br>
      de quelque manière que ce soit et notamment via internet (par exemple via : les réseaux sociaux, le Site, etc.), par exemple en commettant des actes de concurrence déloyale, des actes de parasitisme, des actes d’atteinte à la réputation ou à l’image, des actes de dénigrement, des actes de contrefaçon, des actes portant atteinte à la vie privée,<br></br>
      -	commettre  ou participer ou tenter de commettre ou de participer à une ou des infraction(s) pénale(s), notamment sans que cette liste soit exhaustive : contrefaçon, crimes et délits contre les personnes, mise en danger de la personne, atteintes à la vie de la personne, atteintes à l’intégrité physique ou psychique de la personne, atteintes à la dignité de la personne, atteintes à la personnalité, crimes ou délits contre les biens, infractions de presse, usurpation d’identité, blanchiment d’argent, faux et/ou usage de faux etc. <br></br>
      -	être l’auteur, de quelque manière que ce soit, d’un contenu et/ou de propos illicite(s) et/ou préjudiciable(s) à la Plateforme et/ou aux Commerçants,<br></br>
      -	transmettre, télécharger vers le Site, afficher, de quelque manière que ce soit, et par quelque moyen technologique que ce soit, un contenu illégal, frauduleux, préjudiciable, abusif, menaçant, injurieux, harcelant, à caractère délictuel, diffamatoire, raciste, antisémite, vulgaire, obscène, révisionniste, homophobe, insultant, pornographique, pédophile, contraire aux bonnes mœurs, portant atteinte à la vie privée d'une personne, odieux ou désobligeant ou choquant d'un point de vue racial, ethnique, religieux, ou autre,<br></br>
      -	utiliser ou tenter d’utiliser des moyens de paiements erronés, usurpés, volés, et/ou ne lui appartenant pas.<br></br>
    </p>



    <p className="mb-6 text-xl font-bold">

      b.	Comportement interdit



    </p>

    <p className="mb-6">
      Dans le cadre de l’utilisation des Services, le Client s'interdit notamment, de quelque manière que ce soit, de :<br></br>

    -	manquer aux Règles de Conduite,<br></br>
    -	usurper l’identité d’un tiers réel ou fictif, mentir sur son identité,<br></br>
    -	communiquer à un tiers ses identifiant et mot de passe donnant accès à son Espace Client.
    </p>

    <p className="mb-6 text-xl font-bold">
      c.	Détournement des Services



    </p>

    <p className="mb-6">

      Dans le cadre de l’utilisation des Services, le Client s'interdit notamment, de quelque manière que ce soit, de :<br></br>

    -	détourner ou tenter de détourner tout ou partie : de l’Espace Client, du Site, des Services, ou toute autre élément auquel le Client aurait accès, à des fins autres que celles auxquelles ils sont destinés,<br></br>
    -	tenter de mettre en œuvre ou mettre en œuvre toute action, sous quelque forme que ce soit, présentant un caractère frauduleux et/ou illicite, par exemple :<br></br>
    o	tenter de faire ou faire, directement ou indirectement, une utilisation frauduleuse et/ou illicite de tout ou partie de : l’Espace Client, du Site, des Services, ou tout autre élément auquel le Client aurait accès,<br></br>
    -	donner accès à un tiers à tout ou partie de : l’Espace Client, du Site, des Services ou tout autre élément auquel le Client aurait accès,<br></br> 
    -	exploiter, commercialiser tout ou partie de : l’Espace Client, du Site, des Services ou tout autre élément auquel le Client aurait accès, de quelque manière que ce soit, par exemple sous forme de licence, location, ou d’une revente,<br></br>
    -	tenter de copier ou copier,  de quelque manière que ce soit, sans l'autorisation préalable et écrite de la Plateforme, tout ou partie du Site, (notamment par technique d’aspiration)<br></br>
    -	mener une activité ou inciter une tierce personne à mener une activité illégale ou toute autre activité qui porterait atteinte aux droits de la Plateforme, et/ou de ses Commerçants,  et/ou de tout Client, et/ou plus généralement de tout autre tiers,<br></br>
    -	communiquer à la Plateforme des coordonnées fausses, usurpées, erronées, non actualisées, périmées, par quelque moyen que ce soit, et notamment via le formulaire de création de l’Espace Client sur le Site.


    </p>

    <p className="mb-6 text-xl font-bold">
      d.	Atteinte au fonctionnement du Site et/ou des Services et à la sécurité
    </p>

    <p className="mb-6">
      Dans le cadre de l’Utilisation des Services, le Client s'interdit notamment, de quelque manière que ce soit, de :<br></br>
      -	tenter de mettre en œuvre ou mettre en œuvre, sous quelque forme que ce soit, et quelle que soit la technologie employée, toute action présentant un caractère malveillant et/ou présentant un risque pour le fonctionnement et/ou pour la sécurité de tout ou partie de : l’Espace Client, des Services, du Site, ou toute autre élément auquel le Client aurait accès, par exemple :<br></br>
      o	tenter d’enfreindre ou enfreindre, des procédures, règles encadrant le fonctionnement de tout ou partie : du Site, de l’Espace Client, des Services de tout autre élément en lien avec tout ou partie des Services,<br></br>
      o	tenter de mettre en œuvre ou mettre en œuvre toute action ayant pour conséquence de créer une saturation, d'inonder les serveurs utilisés dans le cadre de l’offre des Services,<br></br>
      o	envoyer vers le Site, de quelque manière que ce soit, et par quelque moyen technologique que ce soit, tout élément contenant : virus logiciel, cheval de Troie, ver, ou autres codes, fichiers ou programmes informatiques espion ou malveillants, par exemple conçus pour interrompre, détruire ou limiter la fonctionnalité de tout logiciel ou matériel informatique ou de tout équipement de télécommunication participant  au fonctionnement de tout ou partie : du Site, de l’Espace Client, des Services, de tout autre élément en lien avec tout ou partie des Services,<br></br>
      -	essayer de porter ou porter atteinte à l’utilisation de tout ou partie : du Site, de l’Espace Client, des Services de tout autre élément en lien avec tout ou partie des Services, par un tiers, (ex : notamment un autre Client),<br></br>
      -	tenter de porter ou porter atteinte aux services fournis par un ou plusieurs des prestataires de la Plateforme, impliqué(s) dans le fonctionnement de tout ou partie : du Site, de l’Espace Client, des Services, de tout autre élément en lien avec tout ou partie des Services,<br></br>
      -	tenter de sonder, sonder, tenter de tester, tester, la vulnérabilité de tout ou partie : du Site, de l’Espace Client, des Services, de toute autre élément en lien avec tout ou partie des Services,<br></br>
      -	tenter d’enfreindre, enfreindre des mesures de sécurité et/ou d'authentification de tout ou partie : du Site, de l’Espace Client, des Services, de toute autre élément en lien avec tout ou partie des Services,<br></br>
      -	tenter accéder ou accéder à un espace, des données, des programmes, des informations, sans y être autorisé par la Plateforme.

    </p>

    <p className="mb-6">
      D’une manière générale, le Client s’interdit de quelque manière que ce soit, d’être ou de tenter d’être l’auteur d’un acte positif ou d’une omission susceptible de causer ou causant un préjudice, de quelque nature que ce soit, à la Plateforme, et plus généralement à tout tiers.


    </p>

    <p className="mb-6">
      Le Client reconnaît qu’une violation de l’article « Obligations du Client » et de l’article « Utilisation interdites » est susceptible de porter préjudice à la Plateforme, et plus généralement à tout tiers et d’engager sa responsabilité.

    </p>

    <p className="mb-6 text-2xl font-bold">

    11.	Protection de la propriété intellectuelle


    </p>

    <p className="mb-6 font-bold text-xl">
    a.	Protection du Site et de ses contenus (« Eléments Protégés »)
    </p>

    <p className="mb-6">

      (i) Principe général<br></br>
      Le Client a choisi de contracter avec la Plateforme en raison de l’originalité de son Site et de ce qu’elle contient.<br></br>
      
      Le Client reconnaît en conséquence que sont protégés par des droits de propriété intellectuelle, sans que cette liste ne soit exhaustive :<br></br>
      
      -	Le Site lui-même, en ce compris notamment :<br></br>
      o	son architecture, <br></br>
      o	sa présentation, <br></br>
      o	sa charte graphique,<br></br>
      -	l’ensemble de ce qu’il contient, et notamment : <br></br>
      o	Eléments logiciels, <br></br>
      o	Œuvres (textes, graphes, graphismes, logos, dessins, images, sons et musique, etc.), sous quelque format que ce soit ;<br></br>
      o	Marques, dénominations sociales, noms commerciaux,<br></br>
      o	Dessins et Modèles.<br></br>
      Les éléments listés ci-dessus sont dénommés ci-après « Eléments Protégés ».<br></br>
      
      Les Eléments Protégés sont, selon le cas, la propriété exclusive de la Plateforme ou d’un tiers.<br></br>


    (ii) Interdictions<br></br>
    Sauf dans le cas où le Client est titulaire des droits de propriété sur l’Elément Protégé concerné, le Client s’interdit, sans que cette liste ne soit exhaustive, notamment de :<br></br>
    -	reproduire, copier, modifier, créer une œuvre dérivée, assembler, recréer, distribuer, présenter, représenter, diffuser, afficher publiquement, transférer, transmettre, publier, vendre, attribuer, sous-licencier, transférer, mettre à disposition d’un tiers, commercialiser, de quelque manière que ce soit, tout ou partie d’Eléments Protégés,<br></br>
    -	d’inverser, décompiler, adapter, traduire, arranger, désassembler ou tenter de quelque manière que ce soit de découvrir le code source d’Eléments Protégés,<br></br>
    -	modifier, altérer, tout ou partie d’Eléments Protégés en vue notamment d'obtenir un accès non autorisé aux Services et d'accéder au Site par un autre moyen que l'interface de connexion à l’Espace Client qui est fournie à cet effet au Client par la Plateforme via le Site.<br></br>
    

    </p>

    <p className="mb-6 font-bold text-xl">

      b.	Protection des Outils de Communication
    
  
    </p>

    <p className="mb-6">
      Le Site contient des Outils de Communication appartenant à la Plateforme.<br></br>
      Le Client reconnaît les droits de la Plateforme sur ses Outils de Communication. <br></br>
      
      Le Client reconnait par avance que toute violation des articles intitulés :<br></br>
      -	« Protection du Site et de ses contenus (« Eléments Protégés ») »,<br></br>
      -	et/ou « Protection des Outils de Communication »,<br></br>
      constitue (selon le cas et l’objet d’autres éventuels manquements contractuels supplémentaires), une contrefaçon et/ou un acte de concurrence déloyale et/ou un acte de parasitisme causant un lourd préjudice pour la Plateforme, notamment en termes d’atteinte à l’image, perte de clientèle, perte de chiffre d’affaires et engage sa responsabilité.<br></br>
    </p>

    <p className="mb-6 text-xl font-bold">
      c.	Protection des Bases de données 


    </p>

    <p className="mb-6">

    
      Les Bases de Données sont protégées par le droit d’auteur, notamment en tant qu’Éléments Protégés.<br></br>
      
      Le Client reconnaît l’ampleur des investissements effectués par la Plateforme afin de développer ses Bases de Données, en particulier sa Base de Données des Commerçants.<br></br>
      
      Le Client reconnaît que la Plateforme a eu l’initiative de la création et du développement de ses Bases de Données.<br></br>
      
      Le Client reconnaît qu’en sus de la qualité de la Plateforme d’auteur de Bases de Données présentant un caractère original, cette dernière a qualité de producteur des bases de données et dispose des droits qui lui sont conférés à ce titre par le code de la propriété intellectuelle.<br></br>
      
      En sa qualité de producteur des Bases de Données, la Plateforme interdit par principe au Client qui l’accepte :<br></br>
      -	(i) l'extraction, par transfert permanent ou temporaire de la totalité ou d'une partie qualitativement ou quantitativement substantielle du contenu des Bases de Données sur un autre support, par tout moyen et sous quelque forme que ce soit,<br></br>
      -	(ii) la réutilisation, par la mise à la disposition du public de la totalité ou d'une partie qualitativement ou quantitativement substantielle du contenu de ses Bases de Données, quelle qu'en soit la forme,<br></br>
      -	(iii) l'extraction ou la réutilisation répétée et systématique de parties qualitativement ou quantitativement non substantielles du contenu des Bases de Données au-delà des conditions normales d’utilisation de ces Bases de Données.
    </p>

    <p className="mb-6 text-2xl font-bold">

      12.	Données à caractère personnel du Client


    </p>

    <p className="mb-6">

      La Plateforme s’engage à traiter les données personnelles du Client dans le respect de la règlementation applicable.<br></br>
      Pour plus d’information sur le traitement de leurs données personnelles par la Plateforme, le Client est invité à consulter sa « Politique de confidentialité ». 
    </p>

    <p className="mb-6 text-2xl font-bold">
    13.	Contenus


    </p>

    <p className="mb-6 text-xl font-bold">
    a.	Qualité d’éditeur du Client pour les Contenus-Clients et responsabilité des Commerçants pour les Contenus-Commerçants


    </p>

    <p className="mb-6">
      (i)	Responsabilité des Commerçants s’agissant des Contenus-Commerçants<br></br>
    
      La Plateforme publie les Contenus-Commerçants sur le Site. Toutefois, le Client reconnait que la Plateforme ne saurait être responsable de l’illicéité ou de l’absence de véracité des informations fournies par les Commerçants et publiées par elle dans le cadre de l’utilisation des Services et qui sont accessibles aux Clients et/ou aux Commerçants et/ou au public, via le Site. Les Commerçants sont responsables de la licéité et de l’exactitude des Contenus-Commerçants.<br></br>
      
      (ii)	Qualité d’éditeur du Client s’agissant de ses Contenus-Clients<br></br>
      
      Le Client reconnaît qu’il a qualité d’éditeur au sens de l’article 6-III de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique de tous les Contenus-Clients qu’il créé et publie dans le cadre de l’utilisation de son Espace Client et qui sont accessibles aux autres Clients et/ou aux Commerçants et/ou au public, via le Site.<br></br>
      
      Le Client garantit que les Contenus-Clients qu’il édite sont licites, exacts, non-erronés, loyaux, à jour, non-périmés et conformes au présent Contrat.<br></br>
      
      A ce titre, le Client est seul responsable de ses Contenus-Clients et des conséquences liées à leur création, suppression, modification, chargement sur le Site, notamment sur : l’Espace Client, et/ou l’Espace Commerçant.<br></br>
      
      Le Client est informé que dans le cas où il éditerait un Contenu-Client illicite et/ou non conforme au présent Contrat, et/ou à la législation, et/ou à la réglementation applicable, la Plateforme se réserve le droit, sur notification de tous tiers, de :<br></br>
      -	vérifier, contrôler, supprimer, rendre l’accès impossible au Contenu-Client litigieux depuis le Site,<br></br>
      -	et/ou suspendre et/ou supprimer l’accès du Client éditeur du Contenu-Client litigieux à son Espace Client,<br></br>
      -	résilier les présentes CGU.

    </p>

    <p className="mb-6">

      b.	Qualité d’hébergeur de la Plateforme s’agissant des Contenus-Commerçants et Contenus-Clients<br></br>
    
    La Plateforme a qualité d’hébergeur des Contenus-Commerçants et des Contenus-Clients au sens de l’article 6-II de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.<br></br>
    
    La Plateforme ne peut être tenue pour responsable des Contenus-Clients et Contenus-Commerçants, de quelque manière que ce soit.<br></br>
    
    Le Client qui constate un Contenu-Client ou un Contenu-Commerçant présentant un caractère illicite s’oblige à le notifier à la Plateforme sans délai.<br></br>
    
    Toute notification (ci-après « la Notification ») s’effectue par lettre recommandée avec accusé de réception adressée à la Plateforme, au lieu de son siège social tel qu’indiqué précédemment, et indique obligatoirement :<br></br>
    -	la date de la Notification,<br></br>
    -	si le notifiant est une personne physique : ses nom, prénoms, profession, domicile, nationalité, date et lieu de naissance, si le requérant est une personne morale : sa forme, sa dénomination, son siège social et l'organe qui la représente légalement,<br></br>
    -	la dénomination et le siège social du destinataire de la Notification, en l’occurrence, la Plateforme,<br></br>
    -	la description des faits litigieux et leur localisation précise dans le Site,<br></br>
    -	les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits,<br></br>
    -	la copie de la correspondance adressée à l'auteur ou à l'éditeur des informations litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l'auteur ou l'éditeur n'a pu être contacté.<br></br>
    
    Cette Notification est doublée d’un email (comprenant la liste des mentions indiquées ci-dessus) envoyé à : support@freeatiz.com.<br></br>
    
    Le Client est informé que la Plateforme ne peut être considérée comme informée de la Notification qu’à condition que toutes les mentions ci-dessus soient renseignées dans la Notification.<br></br>
    
    Suite à une Notification, la Plateforme peut notamment :<br></br>
    -	vérifier, contrôler, supprimer, rendre l’accès impossible au contenu litigieux,<br></br>
    -	et/ou suspendre et/ou bloquer l’accès du Client ou Commerçant concerné à son Espace Commerçant ou Client (selon le cas),<br></br>
    -	et/ou résilier le présent Contrat.<br></br>
    
    Le Client est informé et accepte, que la Plateforme reste seule juge des actions devant être mises en œuvre à la suite d’une Notification, sa responsabilité ne pouvant être engagée du fait des actions mises en œuvre, ou de l’absence d’action, à la suite d’une Notification.
    
  

    </p>
  
    

    <p className="mb-6 text-2xl font-bold">
    14.	Suspension des Services 


    </p>

    <p className="mb-6">
      Le Client accepte que la Plateforme, à sa seule discrétion, suspende temporairement et/ou clôture définitivement son Espace Client, sans préavis en cas de non-respect par le Client d’une stipulation des présentes CGU,<br></br>

      Le  Client reconnaît et accepte qu’il ne pourra plus accéder aux Services ainsi suspendus et/ou clôturés



    </p>

    <p className="mb-6 text-2xl font-bold">
        15.	Résiliation - Cessation d’exploitation
    </p>

    <p className="mb-6 text-xl font-bold">
      a.	Résiliation par le Client
    </p>

    <p className="mb-6">
      Le Client est libre de résilier à tout moment les présentes CGU, en cliquant sur « Supprimer mon compte » dans son Espace Client.<br></br>

      La résiliation prend effet à compter de la date où la demande de résiliation est ainsi formulée.
    </p>

    <p className="mb-6 text-xl font-bold ">
    b.	Résiliation par la Plateforme

    </p>

    <p className="mb-6">
      La Plateforme est libre de résilier, à tout moment, les présentes CGU, par l’envoi d’un email au Client, sous réserve du respect d’un préavis de trente (30) jours.<r></r>
     
      En cas de manquement aux présentes CGU par le Client, la Plateforme se réserve le droit, unilatéralement, sans préavis, de :<br></br>
      -	résilier la relation contractuelle,<br></br>
      -	bloquer l’accès à l’Espace Client,<br></br>
      -	et/ou de bloquer l’accès à tout ou partie des Services, de façon temporaire ou définitive,<br></br>
      sans aucune contrepartie ni indemnité.<br></br>
      
      En présence d’élément(s), sous quelque forme que ce soit (écrits, oraux, etc..) et quelle que soit leur provenance (Plateforme, tout autre tiers…), qui révélerai(en)t le risque d’une utilisation interdite des Services faite par le Client, alors la Plateforme se réserve le droit, unilatéralement, sans préavis, de :<br></br>
      -	résilier la relation contractuelle,<br></br>
      -	bloquer l’accès à l’Espace Client,<br></br>
      -	et/ou de bloquer l’accès à tout ou partie des Services, de façon temporaire ou définitive,<br></br>
      sans aucune contrepartie ni indemnité.
    </p>

    <p className="mb-6 text-xl font-bold">
    c.	Effets de la résiliation quel qu’en soit l’initiateur


    </p>

    <p className="mb-6">
      A l’exception des stipulations mentionnées expressément comme survivant à la résiliation des présentes, la résiliation des présentes CGU opère l’anéantissement de l’ensemble de ses stipulations, et notamment, la cessation de l’accès à l’Espace Client et aux Services.<br></br>
    
      En outre, le Client est informé que la résiliation a pour effet la suppression de son accès à son Espace Client et à l’ensemble de ce qu’il contient.<br></br>
      
      En cas de suppression de l’accès à l’Espace Client imposée par la Plateforme, le Client s’interdit de tenter de contourner, par quelque moyen que ce soit, cette décision de suppression, quelle qu’en soit la cause.
    </p>

    <p className="mb-6 text-2xl font-bold">
    16.	Cessation d’exploitation du Site y compris de l’Espace Client

    </p>

    <p className="mb-6">

      La Plateforme se réserve la faculté de cesser à tout moment et pour quelque cause que ce soit :<br></br>
      -	l’exploitation de son Site,<br></br>
      -	et/ou la fourniture d’un accès aux Espaces Clients.<br></br>
      de plein droit et sans formalité.<br></br>
      La cessation de cette exploitation emporte les mêmes effets que la résiliation.
    </p>


   
    <p className="mb-6 text-2xl font-bold">
    17.	Responsabilité et garanties


    </p>
    

    <p className="mb-6">
      La responsabilité de la Plateforme ne peut être engagée qu’en cas de faute prouvée, dans la limite des garanties énoncées au paragraphe « Garanties » ci-après.<br></br>

      Il est rappelé que la Plateforme et le Client sont convenus que la nature de l’obligation de la Plateforme dans le cadre de la fourniture des Services est une obligation de moyens.<br></br>

      Le présent article « Responsabilité et garanties » reste applicable en totalité et survit en cas de nullité, caducité ou résiliation des présentes CGU, pour quelque cause que ce soit.


    </p>
    

    <p className="mb-6 font-bold text-xl">
        a.	Absence de responsabilité 

    </p>

    <p className="mb-6 font-bold text-xl">
      (i)	Absence de responsabilité de la Plateforme en lien avec la relation contractuelle que le Client noue avec le Commerçant
  </p>
  
    

    <p className="mb-6">

     
    Le Client reconnaît et accepte que la Plateforme n’est pas responsable de la vente qui est conclue entre le Client et le Commerçant dans le cas où le Client passe Commande.<br></br>

    Le Client reconnaît et accepte que le Commerçant est seul responsable à l’égard du Client, de tout acte positif, d’une faute, d’une omission, d’une défaillance, d’une négligence, sous quelque forme que ce soit, commis par le Commerçant, notamment en lien avec (sans que cette liste soit exhaustive) :<br></br>

    -	la formation, et/ou l’exécution, et/ou la terminaison de la relation contractuelle entre le Client et le Commerçant, par exemple, en lien avec :<br></br>
    o	toute pratique commerciale illicite et/ou frauduleuse et/ou abusive du Commerçant,<br></br>
    o	toute inexécution totale ou partielle, ou mauvaise exécution par le Commerçant de ses obligations à l’égard du Client,<br></br>
    o	le niveau de qualité des Produits,<br></br>
    o	tout refus injustifié ou répété d’une Commande,<br></br> 
    o	le délai d’exécution de la Commande, notamment concernant le respect du délai de remise des Produits faisant l’objet de la Commande au Tiers Bénéficiaire dans le cadre du click & collect.<br></br>
    o	la conformité ou non-conformité du Produit :<br></br>
    	aux engagements contractuels du Commerçant,<br></br>
    	à la législation, et/ou réglementation applicable en vigueur (notamment en matière de conformité au droit de la consommation, droit de la publicité, droit des données personnelles, etc.),<br></br>
    	aux normes sanitaires,<br></br>
    o	le contrat de vente de manière plus générale,<br></br>

    -	et/ou toutes informations données par le Commerçant, notamment dans les Contenus-Commerçants, et par exemple, en lien  avec :<br></br>
    o	l’inexactitude, la fausseté, l’illicéité, le caractère mensonger ou trompeur des informations communiquées par le Commerçant (y compris les données  à caractère personnel), avant et/ou pendant l’exécution de la Commande,<br></br>
    o	la fiabilité des informations communiquées par le Commerçant, avant et/ou pendant l’exécution de la Commande,<br></br>
    -	et/ou le respect de la législation et/ou la réglementation qui lui sont applicables,<br></br>
    -	et/ou des Contenus-Commerçants,<br></br>
    -	et de manière, plus générale, les actes, allégations, engagements, obligations, recommandations, et informations que le Commerçant fournit.<br></br>

    En conséquence, la responsabilité de la Plateforme ne saurait être engagée sur le fondement de tout dommage résultant de tout acte positif, d’une faute, d’une omission, d’une défaillance, d’une négligence, sous quelque forme que ce soit, commis par le Commerçant.<br></br>
    </p>
    



    <p className="mb-6 text-xl font-bold">
    (ii)	Absence de responsabilité de la Plateforme en cas d’accès d’un tiers à l’Espace Client
    </p>


    <p className="mb-6">
      Le Client est le seul responsable de l’utilisation, frauduleuse ou non, par un tiers de ses moyens d’accès, ses identifiant et mot de passe, de l’utilisation des Services faite par ce tiers ainsi que des actions et déclarations effectuées par ce dernier depuis l’Espace Client.<br></br>

      Il incombe au Client d’assurer strictement la confidentialité de ses identifiant et mot de passe, lui donnant accès à son Espace Client ; ces identifiant et mot de passe ayant un caractère strictement personnel, le Client s’interdit de les communiquer à un tiers. 

    </p>

    <p className="mb-6 text-xl font-bold">
    (iii)	Absence de responsabilité de la Plateforme concernant l’utilisation du Site, de l’Espace Client et des Services 

      </p>


    <p className="mb-6">
      La Plateforme n’est pas responsable notamment :<br></br>
      -	des difficultés d’accès ou de connexion à tout ou partie : du Site et/ou de l’Espace Client, et/ou des Services,<br></br>
      -	de l’indisponibilité, de dysfonctionnements temporaires affectant en tout ou partie : le Site et/ou l’Espace Client, et/ou les Services,<br></br>
      -	de la suspension momentanée de tout ou partie : du Site et/ou de l’Espace Client, et/ou des Services,<br></br>
      -	de la modification sans préavis de l’accès à tout ou partie : du Site et/ou de l’Espace Client, et/ou des Services,<br></br> 
      et ce, pour quelque cause que ce soit, et notamment en cas de maintenance évolutive ou corrective de tout ou partie du Site et/ou de l’Espace Client, et/ou des Services.

    </p>


    <p className="mb-6 text-xl font-bold">
      (iv)	Absence de responsabilité de la Plateforme en cas de difficulté affectant les Moyens d’Accès du Client
    </p>

   
    <p className="mb-6">
      Le Client reconnaît et accepte que la Plateforme n’est pas responsable de :<br></br>
      -	Défaillance,<br></br>
      -	Vice,<br></br>
      -	Non-conformité,<br></br>
      -	Anomalie,<br></br>
      -	Erreur,<br></br>
      -	Dysfonctionnement,<br></br>
      Affectant les Moyens d’Accès du Client.
    </p>



    <p className="mb-6 text-xl font-bold">
    (v)	Absence de responsabilité de la Plateforme en cas de difficulté affectant les réseaux de communication et télécommunication 
    </p>

   
    <p className="mb-6">

      Le Client reconnait et accepte que la Plateforme n’est pas responsable de :<br></br>
      -	Défaillance,<br></br>
      -	Vice,<br></br>
      -	Non-conformité,<br></br>
      -	Anomalie,<br></br>
      -	Erreur,<br></br>
      -	Dysfonctionnement,<br></br>
      Affectant les réseaux de communication notamment les réseaux de communication et télécoms permettant au Client de se connecter au Site et/ou à son Espace Client et/ou d’utiliser les Services.



     
    </p>
  


  
    <p className="mb-6 font-bold">
      b.	Garanties

    </p>

    <p className="mb-6 font-bold">
      (i) Absence de garanties accordées par la Plateforme
      
    </p>
    <p className="mb-6">
      La Plateforme n’accorde aucune garantie, de quelque nature que ce soit, en lien direct ou indirect avec les Services et notamment concernant les Commerçants, les Fiches Produits et les Fiches Commerçants et le retrait en click & collect des Produits. 

      
    </p>
    <p className="mb-6">
      La Plateforme n’accorde aucune garantie, de quelque nature que ce soit, en lien direct ou indirect avec l’accès et/ou l’utilisation :

      
    </p>
   

    <p className="mb-6">
      -	Du Site, <br></br>
      -	Et/ou de l’Espace Client, <br></br>
      -	Et/ou des Services, <br></br>
      Par le Client.
      
    </p>
    <p className="mb-6">

      Toutes garanties, de quelque nature que ce soit, notamment en termes :

    </p>
    <p className="mb-6">
      -	D’évolutivité, <br></br>
      -	De stabilité, <br></br>
      -	De fiabilité, <br></br>
      -	D’interopérabilité, <br></br>
      -	De sécurité, <br></br>
      -	De compatibilité, <br></br>
      -	D’intégrité, <br></br>
      -	De performance,<br></br>
      Du Site et/ou de l’Espace Client et/ou des Services, sont exclues des présentes CGU.
    </p>
    <p className="mb-6">
      La Plateforme ne répond en aucun cas des dommages directs ou indirects, quelles qu’en soient les causes, origines, natures ou conséquences tels que notamment, les dommages de quelque nature que ce soit, y compris physiques, matériels, immatériels, financiers, perte de chance, gains manques, qui pourraient être subis par le Client a raison d’un fait ou d’un acte pour lequel il est stipulé dans le présent article « Responsabilité et garanties », qu’elle n’en est pas responsable.

      
    </p>
    <p className="mb-6">

La Plateforme ne répond en aucun cas des dommages directs ou indirects, quelles qu’en soient les causes, origines, natures ou conséquences tels que notamment, les dommages de quelque nature que ce soit, y compris physiques, matériels, immatériels, financiers, perte de chance, gains manques, qui pourraient être subis par le Client a raison : 
      
    </p>

    <p className="mb-6">
      -	De l’utilisation du Site et/ou de l’Espace Client, et/ou des Services, <br></br>
      -	De l’impossibilité pour le Client d’accéder au Site et/ou à l’Espace Client, et/ou aux Services, <br></br>
      -	Du crédit accordé à une quelconque information provenant directement ou indirectement de l’utilisation du Site et/ou de l’Espace Client, et/ou des Services.
      
      
      
    </p>

    <p className="mb-6 font-bold">

(ii) Garanties du Client
      
    </p>

    <p className="mb-6">

Le Client garantit le respect des présentes CGU, et notamment le respect de l’article « Obligations du Client » et de l’article « Utilisations interdites », pendant toute la durée d’exécution des présentes CGU.
      
    </p>

   


   










    <p className="mb-6 text-2xl font-bold">
    18.	Règlement des Litiges


    </p>
    

    <p className="mb-6">
        En cas de Litige entre un Client et la Plateforme, la résolution amiable du Litige est privilégiée. Pour ce faire, le Client peut formuler sa réclamation via la rubrique contact ou par email, à l’adresse : support@freeatiz.com.
    </p>
    

    <p className="mb-6 font-bold text-2xl">
        19.	Séparabilité

    </p>
    

    <p className="mb-6">
      Si une ou plusieurs stipulation(s), article(s), phrase(s), parties de phrase(s), mot(s), formule(s), expression(s), des présentes CGU sont tenues pour non valides, nulles, inopérantes, réputées non-écrites ou déclarées comme telles en application d'une loi, d'un règlement ou à la suite d'une décision de justice, les autres mots de la phrase concernée, les autres phrases du paragraphe ou article concerné, et de manière générale, toutes autres stipulations de l’article concerné ainsi que les stipulations restantes des CGU garderont toute leur force et portée. 


    </p>
    

    <p className="mb-6 font-bold text-2xl">
        20.	Force majeure

    </p>
    

    <p className="mb-6">
    La Plateforme n’est pas responsable de manquements au titre de la délivrance ou de l’utilisation des Services résultant d’un cas de force majeure tel que défini par le Code civil et la jurisprudence des Cours et les Tribunaux français.<br></br>

    De convention expresse, sont qualifiés de force majeure le fait du prince, l’émeute, la grève totale ou partielle, interne ou externe à la Plateforme et le lock-out éventuellement consécutif, les intempéries, une pandémie, les actes de guerre, de terrorisme, une action ou un manquement d’une autorité gouvernementale, d’un tiers, le retard ou le manquement des services postaux ou tout autre organisme de transport public ou privé à fournir des produits ou des services, l’arrêt ou le blocage provisoire de fourniture des services télécom, les virus informatiques, le détournement de données notamment via le réseau Internet, l’arrêt de la fourniture d’électricité, l’arrêt d’un groupe électrogène, la panne générale des équipements ou du système informatique de la Plateforme ainsi que tout événement tel qu’incendie, dégât des eaux, catastrophe naturelle, ou tout acte ou événement hors du contrôle raisonnable et exclusif de la Plateforme.
    </p>
    
   

    <p className="mb-6 text-2xl font-bold">
    21.	Modification des Conditions
    </p>


    <p className="mb-6">
      La Plateforme se réserve le droit de modifier les présentes CGU, d’en supprimer, de modifier certaines clauses ou d’en ajouter de nouvelles. La nouvelle version des CGU fait l’objet par le Client d’une acceptation, lors de l’accès de ce dernier à son Espace Client. 
    </p>
  

    <p className="mb-6 text-2xl font-bold">
        22.	Convention de preuve
      </p>
  

    <p className="mb-6">
L’acceptation des présentes CGU par voie électronique a, entre le Client et la Plateforme, la même valeur probante que l’accord sur support papier.

    </p>

    <p className="mb-6 text-2xl font-bold">
      23.	Titre
    </p>


  <p className="mb-6">
      En cas de difficultés d’interprétation résultant d’une contradiction entre l’un quelconque des titres figurant en tête d’un article et le contenu d’un ou plusieurs articles, les titres seront considérés inexistants.
  </p>


  <p className="mb-6 text-2xl font-bold">
      24.	Tolérance
  </p>


<p className="mb-6">
  Le Client reconnaît que le fait pour la Plateforme de tolérer une situation n’a pas pour effet d’accorder au Client des droits acquis.<br></br>

  En outre, ladite tolérance ne peut être interprétée comme une renonciation à faire valoir les droits en cause.
</p>


<p className="mb-6 text-2xl font-bold">
  25.	Cession/Transfert des présentes CGU par la Plateforme
</p>


<p className="mb-6">
Le Client donne expressément et par avance son accord à toute cession ou transfert par la Plateforme du présent contrat, au cessionnaire de son choix, en cas de transfert de tout ou partie des actions de la Plateforme de quelque manière que ce soit, par exemple par acquisition, fusion, absorption, cession, ladite cession ou ledit transfert n’emportant aucune conséquence pour le Client sur les conditions de prix et de qualité des Services. 
</p>


</div>

    </div>
    
}

export default Cgu;