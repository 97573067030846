import TextArea from "antd/es/input/TextArea";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Button, Input, Upload } from "antd";
import { useEffect, useState } from "react";
import app from "../../firebase";
import { logEvent,getAnalytics } from "firebase/analytics";
import {getFunctions, httpsCallable} from 'firebase/functions';
import axios from 'axios';
import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import {message} from 'antd';
const analytics = getAnalytics();
const functions = getFunctions();

const Contact = () => {

    const [firstname,setFirstname] = useState("");
    const [lastname,setLastname] = useState("");
    const [company,setCompany] = useState("");
    const [phone,setPhone] = useState("");
    const [email,setEmail] = useState("");
    const [messageText,setMessageText] = useState("");

    useEffect(()=> {
        logEvent(analytics, 'select_content', {
            content_type: 'image',
            content_id: 'P12453',
            items: [{ name: 'Kittens' }]
          });
    })

    const resetFields = () => {
        setCompany("")
        setFirstname("")
        setLastname('');
        setPhone("")
        setEmail("")
        setMessageText("");
    }

    const sendMessage = () => {

        const sendMsg = httpsCallable(functions,"contactEmail");

        sendMsg({
            firstname,
            lastname,
            email,
            phone,
            company,
            message:messageText
        })
        .then((res)=> {
            resetFields();
            message.success("Message envoyé !")
        })
        .catch(e=>{
            console.log(e);
        })
    }
 

    return (
        <div >
         <div className='flex px-8 pb-4 bg-transparent z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase font-semibold text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>

        <p className="mt-28 text-center text-3xl font-semibold">CONTACTEZ-NOUS</p>
        
        <div className=" py-6 flex flex-col items-center justify-center space-y-2">
            <Input value={lastname} onChange={(e)=> setLastname(e.target.value)} placeholder="Nom" className="w-2/4 h-12 border border-gray-200 rounded-md" />
            <Input value={firstname} onChange={(e)=> setFirstname(e.target.value)} placeholder="Prénom" className="w-2/4 h-12 border border-gray-200 rounded-md" />
            <Input value={company} onChange={(e)=> setCompany(e.target.value)} placeholder="Societé" className="w-2/4 h-12 border border-gray-200 rounded-md" />
            <Input value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Email" className="w-2/4 h-12 border border-gray-200 rounded-md" />
            <Input value={phone} onChange={(e)=> setPhone(e.target.value)} placeholder="Téléphone" className="w-2/4 h-12 border border-gray-200 rounded-md" />
            <TextArea value={messageText} rows={8}  onChange={(e)=> setMessageText(e.target.value)} placeholder="Message" className=" w-2/4 h-28  border border-gray-200 rounded-md resize-none" />
            <button onClick={sendMessage} className="bg-green-500 text-blue-950 shadow-lg p-2 font-semibold rounded-md w-1/4" >ENVOYER</button>
        </div>
        
        <Footer />
        </div>
    )

}

export default Contact;