import React from 'react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
import Footer from '../Footer/Footer';

import Restaurateur from '../Restaurateur/Restaurateur';
import Consommateur from '../Consommateur/Consommateur';
import Presentation from '../Presentation/Presentation';

const Home = () => {
    return <div className='my-0'>
        <Header />
        <Hero />
        <Restaurateur />
        <Consommateur />
        <Presentation />
       

    


        <div className='h-screen pt-10 px-2 my-40 md:mt-10 flex items-center justify-center flex-col'>
            <div className='md:w-3/5 px-20 md:px-0 flex items-start flex-col space-y-3'>


            <p className='text-4xl font-semibold'>Comment offrir un repas</p>
            <p className='text-xl font-semibold'>1. Créez un compte freeatiz</p>
            <p className='text-xl text-gray-500'>
                    Téléchargez l'application freeatiz sur l'apple store ou le google play store, puis créez un compte en renseignant votre nom, prénom et adresse email.
            </p>
            

            <p className='text-xl font-semibold'>
            2. Sélectionnez le restaurant où offrir le freeatiz
            </p>



            <p className='text-xl text-gray-500'>

            Choisissez le repas que vous souhaitez offrir.
            </p>

            <p className='text-xl font-semibold'>

            3. Offrez le freeatiz à un ami, de la famille ou un inconnu.
            </p>

            <p className='text-xl text-gray-500'>
                Faites bénéficier du freeatiz à qui vous le souhaitez en partageant le code.

            </p>


            <p className='text-xl font-semibold'>
                4. La personne choisie peut consommer le freeatiz.

            </p>

            <p className='text-xl text-gray-500'>

                Le bénéficiaire recevra un code, qu'il pourra presenter au restaurateur, pour recevoir le repas.
            </p>


            </div>


        </div>

        <Footer />
        
    </div>
};

export default Home;