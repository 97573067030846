import share from '../../assets/img/share.png'
import easy from '../../assets/img/easy.png'
import fast from '../../assets/img/fusee.png'
import fusee from '../../assets/img/fusee.jpg'

const Presentation = () => {


    return (
        <div className="pt-10">
        <p className='text-center text-4xl px-10 md:px-0 mb-10'>Offrez un repas à travers notre reseau de restaurants partenaires.</p>

        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 items-center justify-center space-x-2 text-black'>
            <div className='border border-gray-300 rounded-xl bg-white p-2 w-3/5 md:w-72 '>
                <p className='text-2xl text-center font-semibold'>Facile</p>
                <p className='text-lg text-gray-500 text-center h-20'>Un code, un repas</p>
                <p>
                    <img className='h-80 w-3/5 rounded-md mx-auto' src={easy} />
                </p>
            </div>
            <div className='border border-gray-300 rounded-xl bg-white p-2 w-3/5 md:w-72'>
            <p className='text-2xl text-center font-semibold'>Rapide</p>
            <p className='text-lg text-gray-500 text-center h-20'>Repas disponible instantanément</p>
            <img className='h-80 w-3/5 rounded-md mx-auto' src={fusee} />



            </div>
        
        </div>
    </div>
    )
}
export default Presentation;