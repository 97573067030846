import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png';
import MenuIcon from '@mui/icons-material/Menu';

const Privacy  = () => {


    return <div className="">
        <div className='flex px-8 pb-4 bg-transparent z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase font-semibold text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>


        <div className="w-4/5 mx-auto mt-28">
    
      <p className="text-3xl font-bold mb-6">
        Politique de confidentialité freeatiz 
      </p>
  
      <p className="mb-6 ">
        Date de Mise à jour : 23/02/2024
      </p>
  
      <p className="mb-6">
        Bienvenue sur la politique de confidentialité de freeatiz !
      </p>
  
      <p className="mb-6">
        freeatiz propose, via son site internet et deux applications, la mise en relation entre des clients, personnes morales ou physiques souhaitant commander des repas, et des restaurateurs proposant leurs repas. Les repas commandés pourront être offerts par le client à un tiers personne physique via la mise à disposition d’un  Code.
      </p>
  
      <p className="mb-6">
        Le présent document a pour objectif de vous présenter simplement quelles sont les informations collectées lors que vous utilisez la plateforme freeatiz, c’est-à-dire le site internet et les deux applications, (ci-après la « Plateforme ») et comment sont-elles traitées. 
      </p>
  
      <p className="mb-6">
        freeatiz vous recommande donc fortement de prêter une attention toute particulière à cette politique de confidentialité.
      </p>
  
      <p className="mb-6">
        QUI EST freeatiz ? 
      </p>
  
      <p className="mb-6">
        freeatiz est la société freeatiz, SAS au capital de 100 euros, ayant son siège social au 7 allée che guevara, 93200 Saint denis, et enregistrée au RCS de Bobigny sous le numéro 978 526 499, qui édite la Plateforme et qui a la qualité de « responsable du traitement » au sens de la législation applicable en matière de protection des données personnelles. 
      </p>
  
      <p className="mb-6">
        Si vous souhaitez en savoir plus sur l’utilisation de la Plateforme, vous êtes invité à consulter ses <Link to="/cgu"><span className="font-bold cursor-pointer">Conditions Générales d’Utilisation. </span></Link>
      </p>
  
      <p className="mb-6">
        Si vous souhaitez directement savoir si freeatiz dépose des cookies et pourquoi, cliquez ici ! 
      </p>
  
      <p className="mb-6 font-bold">
        1.	POURQUOI COLLECTER DES DONNEES VOUS CONCERNANT ? 
      </p>
  
      <p className="mb-6">
        Lorsque vous accédez à la Plateforme, freeatiz va collecter automatiquement des données concernant votre terminal via des cookies. 
      </p>
  
      <p className="mb-6">
        Lorsque vous souhaitez utiliser les services de freeatiz, notamment pour être mis en relation avec des restaurateurs proposant des repas/avec des clients cherchant des repas, freeatiz a besoin de vous identifier et de récupérer des informations concernant les commandes. 
      </p>
  
      <p className="mb-6">
        Au surplus, freeatiz collecte des informations concernant vos demandes lorsque vous lui demandez des informations dans la rubrique « Contact ». 
      </p>
  
      <p className="mb-6">
        De manière générale freeatiz collecte et traite des données personnelles vous concernant (que vous soyez commerçant ou client) :
      </p>
  
      <p className="mb-6 font-bold">
        1/ Sur la base de votre consentement pour :
      </p>
  
      <p className="mb-6">
        •	Vous adresser des informations sur les offres et actualités de freeatiz, par exemple sur les nouveaux restaurateurs ayant rejoint la Plateforme ou les éventuelles promotions que les restaurateurs proposent ;
      </p>
  
      <p className="mb-6">
        •	Améliorer le fonctionnement de la Plateforme en réalisant des statistiques d’audience
      </p>
  
      <p className="mb-6 font-bold">
        2/ Pour répondre à ses obligations contractuelles et vous fournir les services suivants :
      </p>
  
      <p className="mb-6">
      •	Gérer votre compte en tant que client ou restaurateur ;
        
      </p>
  
      <p className="mb-6">
  •	Mettre en relation les clients et les restaurateurs ;
        
      </p>
  
      <p className="mb-6">
  •	Permettre la recherche de restaurateurs en fonction de critères géographiques ou gustatifs 
        
      </p>
  
      <p className="mb-6">
  •	Permettre la passation de commandes sur la Plateforme, y compris gérer le paiement de ces dernières ;
        
      </p>
  
      <p className="mb-6">
  •	Générer des  codes permettant de retirer sa commande sur place soit via du click&collect soit via une dégustation sur place, ou également d’offrir une commande à un tiers bénéficiaire en lui transmettant le  code (notamment par email ou par sms) ;
        
      </p>
  
      <p className="mb-6">
  •	Informer (via des notifications ou des emails) les restaurateurs sur les commandes passées et les conditions choisies par le client ;
        
      </p>
  
      <p className="mb-6">
  •	Informer (via des notifications ou des emails) les clients sur l’état de leur commande (par exemple si elle est disponible pour un retrait en click&collect, sur les conditions de réservation pour un repas sur place ou si la commande a été consommée par son tiers bénéficiaire) ;
        
      </p>
  
      <p className="mb-6">
  •	Editer des contenus des clients ou des restaurateurs (avis sur les restaurateurs, fiche produits, fiche commerçants) ;
        
      </p>
  
      <p className="mb-6">
  •	Gérer tout autre service, actuel ou futur en relation avec les services et inscrit dans les Conditions Générales d’Utilisation applicables.
        
      </p>
  
      <p className="mb-6 font-bold">
        3/ Pour la poursuite des intérêts légitimes de freeatiz qui sont de s’assurer d’optimiser la commercialisation de ses services :
      </p>
  
      <p className="mb-6">
      •	Assurer la gestion de sa relation commerciale avec vous, y compris la gestion d’une liste des clients existants et potentiels et/ou de restaurateurs existants ou potentiels ;
        
      </p>
  
      <p className="mb-6">
      •	Répondre à vos demandes concernant ses services, notamment en traitant vos demandes envoyées via le formulaire de contact ou via le service client ;
        
      </p>
      <p className="mb-6">
      •	Assurer le bon fonctionnement de la Plateforme de manière sécurisée, y compris via le dépôt de cookies.  
        
      </p>
  
      <p className="mb-6 font-bold">
        2.	DANS QUEL CAS VOTRE GEOLOCALISATION PEUT-ELLE ÊTRE COLLECTEE ?
      </p>
  
      <p className="mb-6">
        Lorsque que vous êtes client, freeatiz vous propose de vous géolocaliser sur la Plateforme afin de vous permettre : 
  
  
      </p>
  
      <p className="mb-6">
     -	d’avoir accès à une liste de restaurateurs près de votre position, 
        
      </p>
  
      <p className="mb-6">
      -	d’afficher la distance du restaurateur recherché par rapport à votre propre position. 
        
      </p>
  
      <p className="mb-6">
     -	d’avoir accès à une liste de repas offerts disponible près de votre position, 
        
      </p>
  
    
      <p className="mb-6">
      
        Dans ce cas et dans la mesure où vous souhaitez bénéficier de telles fonctionnalités, <span className="font-bold">vous devez consentir expressément sur la Plateforme</span> et via le paramétrage de votre espace client au recueil de vos données de géolocalisation pour ces finalités. 
        
      </p>
  
            
      <p className="mb-6">
        Les données de géolocalisation seront uniquement collectées lorsque la Plateforme freeatiz est active. 
      </p>
  
      <p className="mb-6">
        Vous pouvez modifier ces choix et retirer votre consentement au traitement de vos données de géolocalisation à n’importe quel moment via le paramétrage de votre espace client.
      </p>
              
  
  
      <p className="mb-6">
        Dans le cas où vous ne souhaitez pas fournir votre géolocalisation à freeatiz, vous pouvez parfaitement bénéficier de ses services en vous contentant de rentrer manuellement votre adresse de position ou toute adresse souhaitée.  
      </p>
              
  
  
      <p className="mb-6">
        Les services peuvent utiliser différentes technologies pour déterminer la localisation, comme le GPS, le Wi-Fi ou d'autres données basées sur le réseau telles que l'adresse IP. freeatiz ne partage pas vos informations de géolocalisation GPS avec des tiers sans votre consentement. 
      </p>
              
  
  
      <p className="mb-6 font-bold">
        3.	QUELLES DONNEES VOUS CONCERNANT freeatiz PEUT-ELLE COLLECTER ? 
      </p>
              
  
  
      <p className="mb-6">
        1/ Informations spécifiques aux clients freeatiz 
      </p>
              
  
  
      <p className="mb-6">
      Aux fins de création de votre espace client et de passation d’une commande, vous devez fournir de manière obligatoire les informations suivantes : 
              
      </p>
              
  
  
      <p className="mb-6">
      -	nom, prénom, adresse email, mot de passe pour vous identifier,
              
      </p>
              
  
  
      <p className="mb-6">
      -	coordonnées de carte bancaire pour le paiement d’une commande. 
              
      </p>
              
  
  
      <p className="mb-6">
      Dans tous les cas, vous pouvez également communiquer de manière facultative des informations complémentaires qui permettent à freeatiz de mieux vous connaître et d’améliorer sa communication à votre égard : âge, vos préférences géographiques et gustatives concernant les restaurateurs, adresse de position, vos notes et avis concernant les restaurateurs auprès desquels vous avez passé commande.
              
      </p>
              
  
  
      <p className="mb-6 font-bold">
        2/Informations spécifiques aux restaurateurs freeatiz
      </p>
              
  
  
      <p className="mb-6">
        Aux fins de création de votre espace commerçant et de bénéficier d’une mise en relation avec des clients, vous devez fournir de manière obligatoire les informations qui sont listées comme telles dans le contrat signé avec freeatiz, notamment : 
      </p>
              
  
      <p className="mb-6">
        -	l’identification de votre société et de votre commerce ;
  
      </p>
  
      <p className="mb-6">
        -	les informations nécessaire à l’établissement d’une fiche commerçant et d’une fiche produit ;
  
      </p>
  
      <p className="mb-6">
        -	vos coordonnées bancaires pour la facturation. 
  
      </p>
  
      <p className="mb-6">
        Toutes les informations qui ne sont pas indiquées comme obligatoires sont communicables de manière facultative. 
  
      </p>
  
      <p className="mb-6 font-bold">
        3/ Informations générales 
      </p>
  
      <p className="mb-6">
        freeatiz collecte directement les informations suivantes auprès de vous lorsque vous utilisez la Plateforme et ses services :
  
      </p>
      <p className="mb-6">
        •	Vos données d’identification et de contact (par exemple, vos nom et prénom, adresse e-mail, numéro de téléphone, votre société, adresse) nécessaires à votre identification ou pour vous contacter lorsque vous utilisez un des services ;
  
      </p>
  
      <p className="mb-6">
        •	Informations transactionnelles nécessaires pour l’utilisation des services (par exemple, caractéristiques des commandes, mode de retrait choisi (consommation sur place ou click&collect), jour et créneau de réservation/de click&collect choisis, adresse de retrait, coordonnées bancaires) ;
  
      </p>
  
      <p className="mb-6">
        •	Données relatives au suivi de la relation commerciale (par exemple, votre identifiant, l’historique des commandes passées/reçues, factures, historique de vos demandes ou de vos correspondances avec le service commercial).
        Les données personnelles indispensables à freeatiz pour remplir les finalités décrites ci-dessus sont signalées au moment de leur collecte. Si vous ne renseignez pas ces champs obligatoires, freeatiz ne pourra pas répondre à vos demandes et/ou vous fournir les services demandés. Les autres informations ont un caractère facultatif et permettent d'améliorer la communication et les services de freeatiz à votre égard.
        D’autres données sont collectées automatiquement lorsque vous accédez à la Plateforme, vous utilisez les services ou lorsque vous réalisez une transaction avec freeatiz :
   
  
      </p>
  
      <p className="mb-6">
        •	Données d’identification et de connexion concernant le terminal utilisé : votre adresse IP, votre type de navigateur et des informations de navigation (date et heures de connexion, la nature et le contenu des requêtes http, etc.) à des fins d’administration et/ou d’établissement de statistiques, comme détaillé dans la Politique cookies.
  
      </p>
  
            
      <p className="mb-6">
        •	Vos données de géolocalisation et identifiants standards des réseaux Wi-Fi détectés, uniquement si vous êtes d’accord pour cela ;  
  
      </p>
  
      <p className="mb-6">
        •	Interactions avec des réseaux sociaux tiers.
  
      </p>
  
  
      <p className="mb-6 font-bold">
        4.	DESTINATAIRES DE VOS DONNEES PERSONNELLES
      </p>
  
  
      <p className="mb-6 font-bold">
        Communication de vos données aux autres utilisateurs de la Plateforme
      </p>
  
  
      <p className="mb-6">
        Lorsque vous publiez des contenus sur la Plateforme (par exemple, fiche produit, fiche commerçant ou avis sur les restaurateurs), ces contenus sont disponibles publiquement à l’ensemble des utilisateurs.
  
        
      </p>
  
  
      <p className="mb-6">
        Lorsque vous interagissez avec d’autres utilisateurs de la Plateforme via votre espace client ou commerçant (par exemple lorsque vous passez commande en tant que client ou recevez une commande en tant que commerçant), freeatiz va transmettre à l’utilisateur concerné les données vous concernant qui sont nécessaires à la bonne réalisation de la transaction demandée. 
  
      </p>
  
  
      <p className="mb-6 font-bold">
        Gestion de la Plateforme et des services
      </p>
  
  
      <p className="mb-6">
        Vos données personnelles sont traitées par le personnel autorisé de freeatiz (par exemple le service commercial). Elles seront également traitées par les sous-traitants et prestataires de freeatiz, intervenant pour des raisons exclusivement techniques et logistiques (par exemple, prestataire de paiement, prestataires d’hébergement et de maintenance de la Plateforme). 
      </p>
  
  
      <p className="mb-6 font-bold">
        Gestion client et prospection
      </p>
  
  
      <p className="mb-6">
        Vos données personnelles sont accessibles aux personnels autorisés de freeatiz avec lesquels vous êtes en contact pour la gestion de la relation commerciale. 
      </p>
  
  
      <p className="mb-6 font-bold">
        Protection et défense des droits de freeatiz
      </p>
  
  
      <p className="mb-6">
        freeatiz pourra être amenée à communiquer à des tiers vos données personnelles lorsqu’une telle communication est requise par la loi, une disposition réglementaire ou une décision judiciaire, ou si cette communication est nécessaire pour assurer la protection et la défense de ses droits (par exemple aux mandataires et conseils externes en cas de recouvrement de créance ; aux assureurs dans le cas où cela serait nécessaire).
      </p>
  
  
      <p className="mb-6 font-bold">
        5.	TRANSFERTS DE VOS DONNEES A L’ETRANGER
      </p>
  
  
      <p className="mb-6">
        Vos données personnelles sont transférées en dehors de l’Espace Economique Européen en raison du recours aux prestataires suivants : Google, Stripe.
      </p>
  
  
      <p className="mb-6">
        Quand vos données personnelles sont transférées en dehors de l’Espace Economique Européen dans des pays dont la législation applicable en matière de protection des données personnelles diffère de celle applicable au sein de l’Espace Economique Européen, ces transferts seront effectués moyennant des garanties appropriées permettant d’assurer un niveau de protection suffisant de la vie privée et des droits fondamentaux, notamment la signature des clauses contractuelles types adoptées par la commission européenne. Une copie des garanties mises en œuvre par freeatiz est communicable sur simple demande adressée à freeatiz.
      </p>
  
  
      <p className="mb-6 font-bold">
        6.	DUREE DE CONSERVATION
      </p>
  
  
      <p className="mb-6">
        freeatiz ne conserve pas vos données personnelles au-delà de la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées.
      </p>
  
  
      <p className="mb-6 font-bold">
        Contenus clients et commerçants
      </p>
  
  
      <p className="mb-6">
        Les données personnelles relatives à vos avis ou vos fiches produits/commerçants sont conservés aussi longtemps que vous êtes client et/ou commerçant (vous disposez d’un espace client/commerçant et avez un contrat en cours avec freeatiz). Elles seront ensuite effacées.
  
        
      </p>
  
  
      <p className="mb-6">
        Vous pouvez supprimer/modifier ces contenus à n’importe quel moment à partir de votre espace client ou commerçant. 
  
      </p>
  
  
      <p className="mb-6 font-bold">
        Contacts et réclamations
  
      
        
      </p>
  
  
      <p className="mb-6">
        Les données personnelles concernant vos demandes collectées et traitées dans le cadre de la rubrique « contact » ou dans le cadre du service commercial sont conservées le temps de répondre à votre demande et pendant 3 ans à compter du dernier contact avec vous. 
         
        
      </p>
  
  
      <p className="mb-6 font-bold">
        Données relatives aux commandes et à la relation commerciale
        
       
      </p>
  
  
      <p className="mb-6">
        Les données relatives aux commandes seront conservées par freeatiz aussi longtemps que vous êtes client et/ou commerçant (vous disposez d’un espace client/commerçant et avez un contrat en cours avec freeatiz). Elles seront ensuite archivées à des fins comptables et probatoires pour satisfaire aux obligations légales de freeatiz pendant une durée maximum de 10 ans à compter de la commande.
         
       
      </p>
  
      <p className="mb-6 font-bold">
        Données d’espace client/commerçant 
        
       
      </p>
  
  
      <p className="mb-6">
        Votre espace client/commerçant sur la Plateforme restera actif tant que vous n’avez pas décidé de le clôturer et tant que vous continuez d’utiliser les services sur la Plateforme. En cas d’inactivité prolongée de votre part pendant une période de 3 ans, cet espace sera désactivé.
         
         
         
      </p>
  
  
      <p className="mb-6">
        Dans tous les cas, vous disposez de la faculté à tout moment de clôturer votre espace. Pour ce faire, vous pouvez contacter le service clients par courrier électronique à l'adresse [à compléter].
  
      </p>
  
  
      <p className="mb-6">
        Enfin, les logs de connexion collectés, sous réserve de votre accord, dans le cadre des cookies et autres traceurs mis en place sur la Plateforme, sont conservés conformément à la réglementation applicable pour une durée n’excédant pas six (6) mois. Pour plus de détails, voir la Politique cookies.
  
      </p>
  
  
      <p className="mb-6 font-bold">
        7.	MODIFICATIONS ET MISES A JOUR DE LA POLITIQUE DE CONFIDENTIALITE 
      </p>
  
  
      <p className="mb-6">
        La Politique de confidentialité peut être modifiée et mise à jour pour refléter les changements dans les pratiques de freeatiz, ou pour assurer le respect de la règlementation en cas de modification de celle-ci. 
   
        
      </p>
  
  
      <p className="mb-6">
        En cas de modification ou de mise à jour, la Politique de confidentialité révisée sera mise en ligne sur la Plateforme, avec mention de la dernière date de mise à jour. Dans ce cas, vous en serez informé par une bannière affichée sur la Plateforme vous invitant à consulter la politique de confidentialité mise à jour.
  
      </p>
  
  
      <p className="mb-6 font-bold">
        8.	 DROIT ET OPTIONS DONT VOUS DISPOSEZ
      </p>
  
  
      <p className="mb-6">
        <span className="font-bold">Accès et rectification</span>
        Vous disposez d’un droit d'accès à vos données personnelles, ainsi que de celui de demander la rectification ou la suppression des données qui s’avèreraient inexactes.  Si vous disposez d’un espace sur la Plateforme, vous pouvez directement accéder aux données contenues dans votre espace en ligne et les rectifier/supprimer le cas échéant.
         
      </p>
  
  
      <p className="mb-6">
        <span className="font-bold">Effacement.</span> Vous pouvez également demander l’effacement des données à caractère personnel vous concernant dès lors que leur conservation par freeatiz n’est plus nécessaire (par exemple si ces dernières ne sont plus nécessaires pour traiter votre commande). 
  
      </p>
  
  
      <p className="mb-6">
        <span className="font-bold">Opposition et Limitation.</span> Vous pouvez également vous opposer au traitement de vos données à caractère personnel ou demander sa limitation, sauf lorsque le traitement est nécessaire à la gestion des commandes et/ou services proposés par freeatiz.
  
      </p>
  
  
      <p className="mb-6">
        <span className="font-bold">Retrait du consentement.</span> Lorsque le traitement de vos données est fondé sur votre consentement, vous pouvez à tout moment retirer ce consentement, notamment pour l’envoi de newsletters ou pour vos données de géolocalisation. 
  
      </p>
  
  
      <p className="mb-6">
        <span className="font-bold">Portabilité.</span> Vous pouvez par ailleurs exercer votre droit à la portabilité (c’est-à-dire les obtenir sous une forme structurée lisible par ordinateur) concernant les données à caractère personnel vous concernant que vous avez fourni directement à freeatiz sur la base du consentement ou d’un contrat conclu avec freeatiz et qui font l’objet d’un traitement automatisé.
  
      </p>
  
  
      <p className="mb-6">
        <span className="font-bold">Directives.</span> Vous pouvez aussi faire part à freeatiz de vos directives concernant la conservation, l’effacement et la communication de vos données à caractère personnel après votre décès et modifier ces directives à tout moment.
  
      </p>
  
  
      <p className="mb-6">
        Ces droits peuvent être exercés directement auprès de freeatiz selon les modalités décrites ci-dessous. Un justificatif d’identité pourra vous être demandé. 
  
      </p>
  
  
      <p className="mb-6 font-bold">
        9.	CONTACTER FREEATIZ
      </p>
      
      <p className="mb-6">
        En cas de questions sur la politique de confidentialité et les pratiques de freeatiz en matière de protection des données personnelles, n'hésitez pas à contacter freeatiz sur la Plateforme via la rubrique « contact » par courrier électronique à support@freeatiz.com, par téléphone au numéro suivant 07 49 02 10 18, ou par courrier adressé à freeatiz, 7 allée che guevara, 93200 Saint denis. 
      </p>
  
  
    </div>
    </div>
}

export default Privacy;