
import imgRestaurateur from '../../assets/img/restaurateur.jpg'

const Restaurateur = () => {


    return <div className='h-screen'>
        <p className='text-center text-4xl font-semibold my-12'>Restaurateur</p>
        <div className='flex flex-col md:flex-row items-center my-6 space-x-2'>
            <div className='md:w-1/2'>
                <img className='rounded-lg w-4/5 mx-auto ' src={imgRestaurateur} />
            </div>
            <div className='md:w-1/2 flex justify-center'>
                <div className='w-4/5 md:w-3/5 flex flex-col space-y-2'>

                <p className='text-center text-2xl font-semibold my-6'>Augmentez vos ventes</p>
            <p className='bg-green-50 text-green-700 p-1 rounded-md font-semibold md:w-fit  text-xl'>Ciblez une nouvelle clientèle</p>
            <p>
            Visez une nouvelle clientèle !
            Rejoignez-nous dès maintenant pour élargir votre audience
             et attirer de nouveaux clients .
            </p>

            <p className='bg-green-50 text-green-700 p-1 rounded-md font-semibold md:w-fit text-xl'>Augmentez vos revenus</p>
            
            <p>
            Augmentez vos ventes grâce à notre large audience et à notre marketing ciblé,
             vous attirerez une nouvelle clientèle vers votre restaurant.
             Rejoignez-nous dès maintenant pour stimuler votre chiffre d'affaires !
            </p>

            <p className='bg-green-50 text-green-700 p-1 rounded-md font-semibold md:w-fit text-xl'>Augmentez votre visibilité</p>

            <p>
            Atteignez une clientèle plus large. Profitez de notre promotion ciblée et de notre base d'utilisateurs fidèles pour augmenter la visibilité de votre restaurant.
            </p>
                </div>
            
            </div>
        </div>
    </div>
}

export default Restaurateur;