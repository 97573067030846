import Header from "../Header/Header";
import app from "../../firebase";
import {confirmPasswordReset, getAuth, verifyPasswordResetCode} from 'firebase/auth';
import { useEffect, useState } from "react";
import { message } from "antd";
import {WarningOutlined} from '@ant-design/icons'
const auth = getAuth(app);





const ResetPassword = () => {

    const [token,setToken] = useState("");
    const [password,setPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const [validLink,setValindLink] = useState(true);
    const [loading,setLoading] = useState(true);
    const [successModified,setSuccessModified] = useState(false);


    const verifyPasswordRequest = () => {
        const oobCode = window.location.search.split("=")[1];

        verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
            setValindLink(true);
        })
        .catch((error) => {
            setValindLink(false);

        })
        .finally(()=>setLoading(false))
    }

    useEffect(()=> {
        verifyPasswordRequest();
        const token = window.location.search.split("=")[1];
        setToken(token);


    },[])


    const setNewPassword = ()=> {
            if(password.length<9){
                message.error("Le mot de passe doit faire plus de 8 caracters")
                return;
            }
            if(confirmPassword.length<9){
                message.error("Le mot de passe doit faire plus de 8 caracters")
                return;
            }
            if(confirmPassword!==password){
                message.error("Les mots de passe ne correspondent pas")
                return;
            }
            confirmPasswordReset(auth, token, password)
            .then((resp) => {
                console.log("Mot de passe modifie");
                setSuccessModified(true);
            })
            .catch((error) => {
                console.log(error)
            }); 
    }

   

    return (
        <>

            {successModified && <div className="h-screen space-y-4 items-center justify-center flex flex-col">
                    <p>Mot de passe modifié ! Vous pouvez vous connecté.</p>
            </div>}
           {validLink && !loading && !successModified && <div className="h-screen space-y-4 items-center justify-center flex flex-col">
                    <input onChange={(e)=>setPassword(e.target.value)} placeholder="Mot de passe" className="border p-2 w-96 border-gray-300 rounded-md " name="password" type="password" />
                    <input onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Confirmer mot de passe" className="w-96 border p-2 border-gray-300 rounded-md " name="confirmPassword" type="password" />
                    <button onClick={setNewPassword} className="bg-green-700 text-white p-2 w-96 rounded-md">CHANGER MOT DE PASSE</button>
            </div>}

            {!validLink && !loading && <div className="h-screen space-y-4 items-center justify-center flex flex-col">
                <p className="text-4xl text-green-700 font-semibold">FREEATIZ</p>
                <div className="flex items-center justify-start flex-col space-y-8 border p-6 w-3/5 border-gray-300 rounded-sm h-1/2">
                <WarningOutlined className="text-6xl font-extralight text-red-800" />
                <p className="text-xl font-medium">Le lien a expiré ! Veuillez soumettre une nouvelle demande.</p>

                </div>
            </div>}

            {loading && <div className="h-screen space-y-4 items-center justify-center flex flex-col">
                <p>Loading...</p>
            </div>}
        </>

    )
}


export default ResetPassword;