
import imgConsommateur from '../../assets/img/consommateur.jpg'

const Consommateur = () => {


    return <div className='h-screen'>
        <p className='text-center text-4xl font-semibold my-12'>Utilisateur</p>

        <div className='md:hidden'>
                <img className='rounded-lg w-4/5 mx-auto  ' src={imgConsommateur} />
            </div>

        <div className='flex flex-col md:flex-row items-center my-6 space-x-2'>
            
            <div className='md:w-1/2 flex justify-center'>
                <div className='w-4/5 md:w-3/5 flex flex-col space-y-2'>

                <p className='text-center text-2xl font-semibold my-6'>Le partage facile</p>
                <p className='bg-green-50 text-green-700 p-1 rounded-md font-semibold md:w-fit  text-xl'>Offrez un repas</p>

            <p>
                Achetez un repas et partagez le en un clin d'oeil.
                En achetant un repas vous recevrez un code que vous pouvez partagez à qui vous le voulez !
            </p>

            <p className='bg-green-50 text-green-700 p-1 rounded-md font-semibold md:w-fit text-xl'>Recevez un repas</p>

            <p>
               Composez un panier et partagez le à un donateur.
               Une fois le panier payé vous recevrez un mail de confirmation et le code dans votre profil !
            </p>

            

                </div>
            
            </div>

            <div className='hidden md:w-1/2 md:flex'>
                <img className='rounded-lg w-4/5 mx-auto  ' src={imgConsommateur} />
            </div>
        </div>
    </div>
}

export default Consommateur;