import './App.css';
import { Route,Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ResetPasswordPro from './components/ResetPasswordPro/ResetPasswordPro';
import Cgv from './components/Cgv/Cgv';
import Privacy from './components/Privacy/Privacy';
import Cgu from './components/Cgu/Cgu';
import Help from './components/Help/Help';
import SharedBasket from './components/SharedBasket/SharedBasket';
import NotFound from './components/NotFound/NotFound';

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route  path="/about" element={<About />}  />
        <Route  path="/contact" element={<Contact />}  />
        <Route  path="/reset-password" element={<ResetPassword />}  />
        <Route  path="/reset-password-pro" element={<ResetPasswordPro />}  />
        <Route  path="/cgv" element={<Cgv />}  />
        <Route  path="/cgu" element={<Cgu />}  />
        <Route  path="/privacy" element={<Privacy />}  />
        <Route  path="/help" element={<Help />}  />
        <Route  path="/sharedBasket/:id" element={<SharedBasket />}  />
        <Route  path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
