import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {

    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <div className={`flex  sticky transition-all duration-500 px-8 ${scrolling ? 'shadow-xl bg-gray-100' : 'bg-white'} text-black  top-0 pb-4    z-30  pt-2 items-center justify-between`}>
    <p >
    <Link to="/">
        <div className="flex items-center space-x-2">
        <img  className='w-16 hover:cursor-pointer' src={logo} /> 
        <p className='text-xl text-green-700 font-semibold'>FREEATIZ</p>
        </div>
      </Link>
    </p>
    <div className='hidden text-lg    md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>

        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Partenaires</p>
        </Link>
       
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>

        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
    </div>

    <div className='md:hidden flex text-white'>
        <MenuIcon />
    </div>
    </div>
};

export default Header;