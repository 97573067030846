import logo from '../../assets/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import sad from '../../assets/img/sad.png';

const NotFound = () => {

    return <div className='h-screen flex flex-col '>
        <div className='flex px-8 pb-4 bg-transparent z-30  pt-2 items-center justify-between'>
    <p >
    <Link to="/">
        <img className='w-24 hover:cursor-pointer' src={logo} /> 
      </Link>
    </p>
    <div className='hidden text-sm uppercase font-semibold text-black  md:flex items-center space-x-2'>
        <Link to="/">
        <p className='hover:cursor-pointer'>Accueil</p>
        </Link>
        <Link to="/help">
        <p className='hover:cursor-pointer'>Aide</p>
        </Link>
        <Link to="/contact">
        
        <p className='hover:cursor-pointer'>Contact</p>
        </Link>
    </div>

    <div className='md:hidden flex text-black'>
        <MenuIcon />
    </div>
    </div>

    <div className='grow flex flex-col space-y-6 lg:flex-row items-center justify-center space-x-6'>
    <div className='lg:w-1/2 flex flex-col items-center justify-end space-y-6'>
        <p className=' lg:w-1/2 mx-20 text-center font-bold text-4xl md:text-6xl'>Cette page n'existe pas !</p>
        <p className='lg:w-1/2 text-xl mx-20 text-center'>
        Nous n'avons pas pu trouver cette page.<br></br>
         Retourner sur <Link to="/"><span className='text-green-500 font-semibold hover:cursor-pointer'>la page d'acceuil</span></Link>.
        </p>
    </div>
    <div className='w-1/2 flex justify-start'>
        <p className='text-left'>
            <img className='w-80' src={sad} />
        </p>
    </div>
    </div>


    <Footer />
    </div>
}

export default NotFound;