import React from 'react';
import iphone from '../../assets/img/iphone.png';
import appleDownload from '../../assets/img/appledownload.svg';
import playStoreDownload from '../../assets/img/googledownload.png';
import hero from '../../assets/img/hero.png';
const Hero = () => {
    return <div  className='flex items-start space-x-2 mx-10'>
        
        
            <div className='md:w-1/2 flex space-y-6 flex-col p-6 justify-center items-center'>
               <p className='text-4xl '>Offrez un repas en un clin d'oeil.</p>
               <p className='text-5xl font-bold'>Offrez un repas en un clin d'oeil.</p>
               <p className='text-3xl text-white'>Freeatiz est l'appli du partage!
                Acheter un repas chez un de nos restaurants partenaires et partagez le
                via un code en un instant à qui vous le voulez.
                
               </p>
               <div className='flex flex-col space-y-2 '>
                <a href="https://apps.apple.com/us/app/freeatiz/id6460259085">
                    <img className='w-60' src={appleDownload} />
                </a>
                <a>
                    <img className='w-60' src={playStoreDownload} />
                </a>
               </div>
            </div>
            <div className='w-1/2'>
            <img className='rounded-md'  src={hero} />

            </div>
        </div>
};

export default Hero;